<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <tabel-alat></tabel-alat>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import tambahAlat from './component/tambahAlat.vue';
import daftar_alat from './component/daftar_alat.vue'
import tabelAlat from './tabel.vue';
import {
  BRow,
  BCol,
  BForm,
  BCard,
  BCardBody, BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
import CardAlat from './component/CardAlat.vue'
import Pagination from './component/pagination.vue';
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BCard,
    BCardBody,
    BFormInput,
    BFormGroup,
    daftar_alat,
    CardAlat, Pagination, tambahAlat, tabelAlat
  }

}
</script>

<style>
.mbt {
  margin-bottom: -20px;
}

.mtt {
  margin-top: -2px;
}
</style>
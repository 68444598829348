<template>
  <div>
    <!-- basic modal -->
    <b-modal id="detail_list_alat_detail" title="Informasi Alat Produksi" ok-disabled no-close-on-backdrop hide-footer>
      <b-card-text>
        <!-- {{ input }} -->
        <!-- {{ rs_data }}
        {{ rs_edit }} -->
        <b-alert variant="warning" show>
          <div class="alert-body">
            <span><strong>Kode Inventasis - {{ input.kode_item }}</strong><br> Tanggal Pengadaan Alat {{
              input.tgl_pembelian }}</span><br>
            <small v-if="input.status_peminjaman == 'Sedang Dipinjam'"><feather-icon icon="GridIcon" size='15' /> Saat
              ini unit alat {{ input.status_peminjaman }}</small>
          </div>
        </b-alert>
        <b-form-group label="Status Alat Produksi" label-for="basicInput">
          <div class="demo-inline-spacing" style="margin-top: -20px;">
            <b-form-radio v-model="input.status" name="some-radios" value="Normal">
              Normal
            </b-form-radio>
            <b-form-radio v-model="input.status" name="some-radios" value="Rusak">
              Rusak
            </b-form-radio>
            <b-form-radio v-model="input.status" name="some-radios" value="Perbaikan">
              Perbaikan
            </b-form-radio>
            <b-form-radio v-model="input.status" name="some-radios" value="Tidak bisa digunakan">
              Tidak bisa digunakan
            </b-form-radio>
          </div>
        </b-form-group>

        <b-form-group label="Keterangan" label-for="Keterangan">
          <b-form-textarea id="textarea-default" placeholder="Textarea" rows="3" v-model="input.keterangan" />
        </b-form-group>
      </b-card-text>
      <b-row>
        <b-col>
          <b-button variant="warning" class="btn btn-sm btn-block" @click="UpdateDetailList()"><feather-icon
              icon="SaveIcon" size='15' /> Update
            Status Alat
            Produksi</b-button>
        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BFormRadio, BFormTextarea, BFormGroup, BRow, BCol } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton, BRow, BCol, BModal,
    BAlert, BFormRadio, BFormTextarea, BFormGroup,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ["UpdateDetailList"],
  props: {
    rs_edit: {},
    rs_data: {}
  },
  computed: {
    input: {
      get: function () {
        return this.rs_edit;
      },
      set: function (newValue) {
        return this.rs_edit = newValue;
      }
    },
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
  methods: {
    UpdateDetailList() {
      this.$emit("UpdateDetailList", this.input)
      this.close();
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'detail_list_alat_detail')
    }
  }
}
</script>
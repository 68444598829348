<template>
  <div>
    <b-form-group label="Unggah Foto Alat Produksi">
      <b-form-file v-model="file" placeholder="Choose a file or drop it here..." ref="file" id="customFile"
        @input="uploadFile" drop-placeholder="Drop file here..." />
      <b-img thumbnail fluid :src="rsDetail.foto_respon1" />
    </b-form-group>
    <b-button v-show="file != ''" class="btn btn-primary btn-sm btn-block" @click=simpanFoto(fileUpload)><feather-icon
        icon="ShareIcon" size='15' />Upload </b-button>
  </div>
</template>

<script>
import { BFormFile, BFormGroup } from 'bootstrap-vue'
import AxiosFile from '@/config/AxiosFile';
export default {
  components: {
    BFormFile,
    BFormGroup,
  },
  data() {
    return {
      rsDetail: {},
      fileUpload: {},
      file: []
    }
  },
  emits: ['simpanFoto'],
  methods: {
    simpanFoto() {
      this.$emit('simpanFoto', this.fileUpload)
    },
    uploadFile() {
      const isAuthenticated = localStorage.getItem('token_auth');
      this.file = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append('file', this.file);
      const self = this;
      AxiosFile.post('/upload/prodak', formData, {
        headers: {
          'Authorization': isAuthenticated,
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(function (response) {
          self.file = {};
          self.fileUpload = response.data.result.nm_file;


        })
        .catch(function (error) {
          console.log(error);
        });

    },
  }
}
</script>
<template>
  <b-card no-body class="card-apply-job">
    <b-card-header class="pb-1">
      <b-media no-body>
        <b-media-body>
          <h4 class="mb-0">
            Hand Sealer
          </h4>
          <small class="text-muted">Kode Alat 0107</small>
        </b-media-body>
      </b-media>
    </b-card-header>
    <b-card-body>
      <b-card-text class="mb-2">
        Hand sealer adalah alat perekat kemasan plastik dan aluminium foil yang sering digunakan untuk mengemas berbagai
        produk makanan dan minuman. Alat perekat plastik dan aluminium foil ini sering digunakan untuk pengemasan
        standing pouch, kemasan sachet dan lainnya.
      </b-card-text>
      <b-row class="mb-1">
        <b-col>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="primary" v-b-modal.modal-3>
            <feather-icon icon="EditIcon" size='15' />
            Detail Alat
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6"> <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="warning" v-b-modal.modal-2>
            <feather-icon icon="EditIcon" size='15' />
            Edit
          </b-button>
        </b-col>
        <b-col md="6"> <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="danger">
            <feather-icon icon="TrashIcon" size='15' />
            Hapus
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
    <edit-alat></edit-alat>
    <detailalat></detailalat>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BMedia, BMediaAside, BAvatar, BMediaBody, BBadge, BCardText, BCardBody, BButton, BCol, BRow
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import EditAlat from './editAlat.vue'
import detailalat from './DetailAlat.vue';

export default {
  components: {
    BCard,
    BButton, BCol, BRow,
    BBadge,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
    BCardBody,
    EditAlat, detailalat
  },
  directives: {
    Ripple,
  },
}
</script>

<template>
  <div>
    <b-modal id="modal-3" :title="rs_data.nama_alat" ok-disabled no-close-on-backdrop size="lg" hide-footer>
      <b-card-text>
        <p> {{ rs_data.keterangan }}</p>
        <b-row>
          <b-col md="5">
            <b-carousel id="carousel-example-generic" controls indicators>
              <span v-if="fotoList.result == null"> <b-carousel-slide
                  :img-src="require('@/assets/images/slider/01.jpg')">
                </b-carousel-slide> </span>
              <span v-for="fotoList in fotoList.result" v-bind:key=fotoList.id>
                <b-carousel-slide :img-src="fotoList.img">

                  <b-badge class="btn btn-danger btn-sm" @click="HapusFoto(fotoList)"><feather-icon icon="TrashIcon"
                      size='15' /></b-badge>
                </b-carousel-slide>

              </span>
            </b-carousel>
            <b-form-group label="Small:" label-for="file-small" label-cols-sm="2" label-size="sm">
              <b-form-file id="file-small" size="sm" />
            </b-form-group>
            <hr>
            <detail-upload-foto @simpanFoto=simpanFoto></detail-upload-foto>
          </b-col>
          <b-col md="7">
            <detail-list-alat :rs_data=rs_data :itemData="itemData.result" @loadAlatList=loadAlatList
              @loadITemAlat="loadITemAlat"></detail-list-alat>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BImg, BCarousel, BCarouselSlide, BRow, BCol, BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import DetailListAlat from './DetailListAlat.vue'
import DetailUploadFoto from './DetailUploadFoto.vue'

export default {
  components: {
    BButton,
    BModal,
    BAlert, BImg, BCarousel,
    BCarouselSlide, BRow, BCol, BBadge,
    DetailListAlat,
    DetailUploadFoto
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {

    }
  },
  props: {
    rs_data: {},
    fotoList: {},
    itemData: {}
  },
  mixins: [Base],
  mounted() {


  },
  emits: ["loadITemAlat", 'HapusFoto', 'simpanFoto'],
  methods: {
    simpanFoto(val) {
      this.$emit('simpanFoto', val, this.rs_data.id_alat_produksi)
    },
    HapusFoto(val) {
      if (confirm('apakah anda yakin akan menghapus foto ini..?')) {
        this.$emit('HapusFoto', val)
      }

    },
    loadITemAlat(id) {

      this.$emit('loadITemAlat', id)
    },
    close() {
      this.$root.$emit('bv::hide::modal-3', 'AddLevel')
    }
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>
<template>
  <b-pagination-nav :link-gen="linkGen" :number-of-pages="10" use-router class="mb-0" align="right" />
</template>

<script>
import { BPaginationNav } from 'bootstrap-vue'

export default {
  components: {
    BPaginationNav,
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
  },
}
</script>
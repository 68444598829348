<template>
  <div>

    <!-- basic modal -->
    <b-modal id="modal-tambah_alat" title="Penambahan Alat Produksi" ok-disabled no-close-on-backdrop hide-footer>

      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group label="Tanggal Pembelian Alat" label-for="Tanggal Pembelian Alat">
                <validation-provider #default="{ errors }" rules="required" name="Tanggal Pembelian Alat">
                  <flat-pickr v-model="itemAlat.tgl_pembelian" class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d' }" @change="handleDateChange" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--  This field is required-->
            <b-col md="12">
              <b-form-group label="Keterangan" label-for="keterangan">
                <validation-provider #default="{ errors }" rules="required" name="keterangan Alat">
                  <b-form-input v-model="itemAlat.keterangan" :state="errors.length > 0 ? false : null"
                    placeholder="Keterangan Alat Produksi" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-button variant="primary" class="btn-sm btn-block" type="submit" @click.prevent="validationForm">
                <feather-icon icon="SaveIcon" size='15' /> Simpan
              </b-button>

            </b-col>

            <b-col cols="6">
              <b-button variant="warning" class="btn-sm btn-block" type="submit" @click.prevent="close()">
                <feather-icon icon="XIcon" size='15' /> Batalkan
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>


    </b-modal>

  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required
} from '@validations'
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BForm, BFormGroup, BFormInput, BRow, BCol } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton, ValidationProvider, ValidationObserver,
    BModal, BRow, BCol, flatPickr,
    BAlert, BForm, BFormGroup, BFormInput
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      itemAlat: {

      }
    }
  },
  mixins: [Base],
  mounted() {
  },
  emits: ["simpanAlatProduksi"],
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    handleDateChange(selectedDates) {
      // Perform any additional logic if needed
      this.closeModal(); // Close the modal when a date is selected
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$emit('simpanAlatProduksi', this.itemAlat, this.rs_data.id_alat_produksi)
          this.itemAlat = {};
          this.close();
        }
      })
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-tambah_alat')
    }
  },
  props: {
    rs_data: {}
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
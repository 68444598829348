<template>
  <div>
    <!-- basic modal -->
    <b-modal id="modal-2" title="Edit Alat Produksi" ok-disabled no-close-on-backdrop size="lg" hide-footer>
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="4">
              <b-form-group label="Kode Alat">
                <validation-provider #default="{ errors }" name="Kode Alat" rules="required">
                  <b-form-input v-model="rsEdit.kode_alat" :state="errors.length > 0 ? false : null" disabled
                    placeholder="Kode Alat" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="8">
              <b-form-group label="Nama Alat">
                <validation-provider #default="{ errors }" name="Nama Alat" rules="required">
                  <b-form-input v-model="rsEdit.nama_alat" :state="errors.length > 0 ? false : null"
                    placeholder="Nama Alat Produksi" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Keterangan">
                <validation-provider #default="{ errors }" name="Deskripsi Alat Produksi" rules="required">
                  <b-form-textarea v-model="rsEdit.keterangan" :state="errors.length > 0 ? false : null"
                    id="textarea-default" placeholder="Deskripsi Alat Produksi" rows="3" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button variant="warning" size="sm" type="submit" @click.prevent="validationForm">
                <feather-icon icon="SaveIcon" size='15' /> Update
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BFormInput, BFormGroup, BForm, BRow, BCol, BCardText, BFormTextarea } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
export default {
  components: {
    BButton,
    BModal,
    BAlert,
    BFormInput, BFormGroup, BForm, BRow, BCol, BCardText, ValidationProvider, ValidationObserver, BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      input: {
        kode_alat: '',
        nama_alat: '',
        keterangan: ''
      }
    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ["updateAlat"],
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$emit('updateAlat', this.rsEdit)
          this.close();
        }
      })
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-2')
    }
  },
  props: {
    rsEdit: {}
  },
  computed: {


  },
}
</script>
<template>
  <b-row>
    <b-col md="5" sm="8" class="my-1">
      <strong> <feather-icon icon="AlignJustifyIcon" size='15' /> Daftar Alat Produksi </strong>
    </b-col>
    <b-col md="1" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" />
      </b-form-group>
    </b-col>
    <b-col md="4" class="my-1">
      <b-form-group label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col md="2" sm="4" class="my-1">
      <b-button variant="primary" class="btn  btn-sm btn-block" v-b-modal.modal-1>
        <feather-icon icon="PlusCircleIcon" size='15' /> Tambah
      </b-button>

    </b-col>
    <tambah-alat @simpanAlat="simpanAlat"></tambah-alat>
    <edit-alat :rsEdit=rsEdit @updateAlat="updateAlat"></edit-alat>
    <b-col cols="12">
      <b-table responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :show-head="false" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
        :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">


        <template #cell(id)="data">
          <b-row class="match-height">
            <b-col md="6">
              <b-alert variant="" show style="margin-left: -30px;">
                <div class="alert-body" style="text-align: justify;">
                  <span class="text-info">{{ data.item.nama_alat }} </span><br>
                  <small style="text-align: justify;">{{ data.item.keterangan }}</small>
                </div>

              </b-alert>

            </b-col>
            <b-col md="6">
              <b-alert variant="info" show style="">
                <div class="alert-body">
                  <span> <strong>Total {{ parseInt(data.item.jm_aktif) + parseInt(data.item.jm_perbaikan) +
                    parseInt(data.item.jm_rusak) }} Unit</strong> </span><br>
                  <b-row>
                    <b-col md="4">
                      <small>Redy : {{ data.item.jm_aktif }} Unit </small>
                    </b-col>
                    <b-col md="4">
                      <small>Rusak : {{ data.item.jm_rusak }} Unit
                      </small>
                    </b-col>
                    <b-col md="4">
                      <small> Perbaikan {{ data.item.jm_perbaikan }} Unit </small>
                    </b-col>
                  </b-row>

                </div>
              </b-alert>

              <b-row>
                <b-col md="4">
                  <b-button variant="primary" class="btn  btn-sm btn-block" v-b-modal.modal-3
                    @click="DataLoad(data.item)">
                    <feather-icon icon="AlignJustifyIcon" size='15' /> Detail
                  </b-button>
                </b-col>
                <b-col md="4">
                  <b-button variant="warning" class="btn  btn-sm btn-block" v-b-modal.modal-2
                    @click="GetEdit(data.item)">
                    <feather-icon icon="EditIcon" size='15' /> Edit
                  </b-button>
                </b-col>
                <b-col md="4" v-if="parseInt(data.item.jm_aktif) + parseInt(data.item.jm_perbaikan) +
                  parseInt(data.item.jm_rusak) < 1">
                  <b-button variant="danger" class="btn  btn-sm btn-block" @click="hapusData(data.item)">
                    <feather-icon icon="TrashIcon" size='15' /> Hapus
                  </b-button>
                </b-col>
              </b-row>
              <!-- https://www.tokomesin.com/shop/mesin-gula-kapas-cotton-candy-gulali-et-mf01 -->

            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-col>

    <b-col cols="12">
      <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
    <detailalat :rs_data=rs_data :fotoList="fotoList" :itemData="itemData" @loadITemAlat="loadITemAlat"
      @HapusFoto=HapusFoto @simpanFoto="simpanFoto">
    </detailalat>
  </b-row>
</template>

<script>
import axios from '@/config/Axios';
import {
  BTable, BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,
  BCard, BAlert, BCardBody,
} from 'bootstrap-vue'
import Base from '@/config/Mixins_base';
import EditAlat from './component/editAlat.vue'
import detailalat from './component/DetailAlat.vue';
import DetailAlat1 from '../peminjaman_alat/component/DetailAlat.vue';
import tambahAlat from './component/tambahAlat.vue';

export default {
  components: {
    BTable, BAlert,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton, BCard, BCardBody, EditAlat, detailalat,
    DetailAlat1, tambahAlat
  },
  data() {
    return {
      rsEdit: [],
      rs: [],
      input: {},
      rs_data: [],
      fotoList: [],
      itemData: [],
      isAuthenticated: localStorage.getItem('token_auth'),
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'id', label: 'Id',
          thStyle: {
            display: 'none'
          }
        },

      ],
      items: [
      ],
      data_item: {},
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  mixins: [Base],
  computed: {
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.loadData();
    this.totalRows = this.items.length
  },
  methods: {
    async simpanFoto(img, id) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/admin/alat_produksi/insert_foto',
        data: {
          "id_alat_produksi": id,
          "img": img
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('info', "Info", response.data.result.pesan);
          self.DataLoad(self.rs_data);

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },

    async HapusFoto(val) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/admin/alat_produksi/delete_foto',
        data: {
          id: val.id
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs = response.data.result;
          self.loadData();
          self.notification('info', "Info", response.data.result.pesan);

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    GetEdit(rsEdit) {
      this.rsEdit = rsEdit
    },
    async updateAlat(rsEdit) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/admin/alat_produksi/update',
        data: rsEdit,
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs = response.data.result;
          self.loadData();
          self.notification('info', "Info", 'Daata alat produksi berhasil di update');

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async hapusData(id_alat_produksi) {
      if (confirm("Apakah anda yakin akan menghapus data ini")) {
        const self = this;
        await axios({
          method: 'POST',
          url: '/api/admin/alat_produksi/delete',
          data: {
            id_alat_produksi: id_alat_produksi.id_alat_produksi
          },
          headers: {
            'Authorization': self.isAuthenticated
          }
        })
          .then(function (response) {
            self.rs = response.data.result;
            self.loadData();
            self.notification('info', "Info", "Data Berha,sip dihapus");
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      }
    },
    async simpanAlat(inputData) {
      const self = this;
      await axios({
        method: 'POST',
        url: 'api/admin/alat_produksi/insert',
        data: inputData,
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs = response.data.result;
          self.loadData();
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    DataLoad(rs_data) {
      this.rs_data = rs_data
      this.load_foto(rs_data.id_alat_produksi);
      this.loadITemAlat(rs_data.id_alat_produksi);
    },
    async loadITemAlat(id_alat_produksi) {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/admin/alat_produksi/get_item',
        data: {
          id_alat_produksi: id_alat_produksi
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.itemData = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async load_foto(id_alat_produksi) {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/admin/alat_produksi/load_foto',
        data: {
          id_alat_produksi: id_alat_produksi
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.fotoList = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async loadData() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/admin/alat_produksi/get_data',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.items = response.data.result.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<template>
  <div>
    <!-- basic modal -->
    <b-modal id="modal-3" title="Hand Sealer" ok-disabled no-close-on-backdrop size="lg" hide-footer>
      <b-card-text>
        <p>Hand sealer adalah alat perekat kemasan plastik dan aluminium foil yang sering digunakan untuk mengemas
          berbagai produk makanan dan minuman. Alat perekat plastik dan aluminium foil ini sering digunakan untuk
          pengemasan standing pouch, kemasan sachet dan lainnya.</p>
        <b-row>
          <b-col md="6">

            <b-carousel id="carousel-example-generic" controls indicators>
              <b-carousel-slide :img-src="require('@/assets/images/slider/01.jpg')">
                <b-badge class="btn btn-info btn-sm mr-1"><feather-icon icon="EditIcon" size='15' /></b-badge>
                <b-badge class="btn btn-danger btn-sm"><feather-icon icon="TrashIcon" size='15' /></b-badge>
              </b-carousel-slide>
              <b-carousel-slide :img-src="require('@/assets/images/slider/02.jpg')">
                <b-badge class="btn btn-info btn-sm mr-1"><feather-icon icon="EditIcon" size='15' /></b-badge>
                <b-badge class="btn btn-danger btn-sm"><feather-icon icon="TrashIcon" size='15' /></b-badge>
              </b-carousel-slide>
              <b-carousel-slide :img-src="require('@/assets/images/slider/03.jpg')">
                <b-badge class="btn btn-info btn-sm mr-1"><feather-icon icon="EditIcon" size='15' /></b-badge>
                <b-badge class="btn btn-danger btn-sm"><feather-icon icon="TrashIcon" size='15' /></b-badge>
              </b-carousel-slide>
            </b-carousel>
            <b-form-group label="Small:" label-for="file-small" label-cols-sm="2" label-size="sm">
              <b-form-file id="file-small" size="sm" />
            </b-form-group>
            <hr>
            <detail-upload-foto></detail-upload-foto>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BImg, BCarousel, BCarouselSlide, BRow, BCol, BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BModal,
    BAlert, BImg, BCarousel,
    BCarouselSlide, BRow, BCol, BBadge,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {

    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: [""],
  methods: {
    close() {
      this.$root.$emit('bv::hide::modal-3', 'AddLevel')
    }
  },
  props: {

  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>
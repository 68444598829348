<template>
  <b-row>
    <b-col md="9" sm="9" xs="9" class="my-1">
      <b-form-group label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Pencaria Alat" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col md="3" sm="3" xs="3" class="my-1">
      <b-button class="btn btn-primary btn-sm" v-b-modal.modal-tambah_alat><feather-icon icon="PlusCircleIcon"
          size='15' /></b-button>
    </b-col>
    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(kode_item)="data">
          <b-row>
            <b-col md="7"> <strong>{{ data.item.kode_alat }} - {{ data.item.kode_item }}</strong><br>
              <small><i>Tgl Pengadaan {{ data.item.tgl_pembelian }}</i></small></b-col>
            <b-col md="5">
              <b-badge style="margin-bottom: 5px;" class="d-block " variant="warning"
                v-if="data.item.status_peminjaman == 'Sedang Dipinjam'">{{
                  data.item.status_peminjaman
                }}</b-badge>
              <b-badge style="margin-bottom: 5px;" class="d-block" variant="danger" v-if="data.item.status != 'Normal'">
                {{
                  data.item.status
                }}</b-badge>
              <b-badge class="  d-block" variant="light-info" v-b-modal.detail_list_alat_detail
                @click="EditData(data.item, rs_data)">
                <feather-icon icon="EditIcon" size='15' /> Edit
              </b-badge>
            </b-col>
          </b-row>


        </template>
      </b-table>
    </b-col>

    <b-col cols="12">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
    <detai-list-alat-tambah :rs_data="rs_data" @simpanAlatProduksi=simpanAlatProduksi></detai-list-alat-tambah>
    <detail-list-alat-detail :rs_edit=rs_edit :rs_data="rs_data"
      @UpdateDetailList="UpdateDetailList"></detail-list-alat-detail>
  </b-row>
</template>

<script>
import Base from '@/config/Mixins_base';
import axios from '@/config/Axios';
import {
  BTable, BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import DetaiListAlatTambah from './DetaiListAlatTambah.vue'
import DetailListAlatDetail from './DetailListAlatDetail.vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    DetaiListAlatTambah,
    DetailListAlatDetail,
  },
  mixins: [Base],
  data() {
    return {
      rs: [],
      rs_edit: [],
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'kode_item', label: 'ALat Produksi', sortable: true },
      ],
      status: [{
        1: 'Dipinjam', 2: 'Redy', 3: 'Rejected', 4: 'Resigned', 5: 'Dipinjam',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  props: {
    itemData: {},
    rs_data: {}
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    items: {
      get: function () {
        return this.itemData;
      },
      set: function (newValue) {
        return this.itemData = newValue;
      }
    },
  },
  emits: ["loadAlatList", "loadITemAlat"],

  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    EditData(item,) {
      this.rs_edit = item

    },

    async UpdateDetailList(val) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/admin/alat_produksi/update_item',
        data: {
          status: val.status,
          keterangan: val.keterangan,
          id_item_alat_prodiksi: val.id_item_alat_prodiksi

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs = response.data.result;
          self.notification('Info', "Info", response.data.result.pesan);

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },

    async simpanAlatProduksi(itemAlat, id_alat_produksi) {

      const self = this;
      await axios({
        method: 'POST',
        url: '/api/admin/alat_produksi/insert_item',
        data: {
          id_alat_produksi: id_alat_produksi,
          tgl_pembelian: itemAlat.tgl_pembelian,
          keterangan: itemAlat.keterangan
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs = response.data.result;
          self.$emit("loadITemAlat", id_alat_produksi);
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>